import {Pipe, PipeTransform} from '@angular/core'
import {map, Observable, of} from 'rxjs'

import {RoleCode} from '@core/schemes/role'
import {UserService} from '@core/services/http/user.service'

@Pipe({
  standalone: true,
  name: 'hasRoles',
})
export class HasRolesPipe implements PipeTransform {

  constructor(
    private userService: UserService,
  ) {
  }

  transform(argRoles: RoleCode[]): Observable<boolean> {
    if (!argRoles || !argRoles.length) return of(true)
    return this.userService.user$.pipe(map(user => user ? this.userService.hasRoles(argRoles) : false))
  }
}
